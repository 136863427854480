import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: ${CSSVars.gray900};
	max-width: 1420px;
	margin: 0 auto;
	padding-top: 200px;

	@media screen and (min-width: ${CSSVars.breakpointMD}) {
		padding-top: 0;
	}

	h2 {
		margin: 0 auto 50px;
		font-size: ${CSSVars.fontSize2XL};
		font-weight: 600;
		text-align: center;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}

		span {
			color: ${CSSVars.primary500};
		}
	}

	.benefits {
		display: flex;
		flex-wrap: wrap;
		gap: 50px;

		.card {
			flex: 300px;
			h3 {
				font-weight: 600;
				font-size: ${CSSVars.fontSizeLG};
				margin-bottom: 10px;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeXL};
				}
			}

			p {
				font-size: ${CSSVars.fontSizeMD};
				font-weight: 300;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeLG};
				}
			}
		}
	}
`;
