import React from 'react';
import { Section } from '../section/section.comp';
import { StyledDiv } from './widgetSocialProof.styles';
import Image from 'next/image';

export const WidgetSocialProof = () => {
	return (
		<Section>
			<StyledDiv>
				<div className="reviews">
					<div className="review">
						<Image
							width={60}
							height={60}
							src={
								'https://website-assets.commoninja.com/distribution/1708193165182_1655142254598.jpeg'
							}
							alt={`Marcela Soto, ReasonWhy`}
						/>
						<h3>Marcela Soto, ReasonWhy</h3>
						{renderStars()}
						<p>
							"Kudos to the Common Ninja team for delivering both an outstanding
							product and excellent customer service. I highly recommend them to
							anyone looking for a top-notch solution!"
						</p>
					</div>
					<div className="review">
						<Image
							width={60}
							height={60}
							src={
								'https://website-assets.commoninja.com/distribution/1708193168374_1681486227773.jpeg'
							}
							alt={`Roberto Sterza, KRENDALL srl`}
						/>
						<h3>Roberto Sterza, KRENDALL srl</h3>
						{renderStars()}
						<p>
							"Support it's incredible: I have sent an email about a bug and the
							SAME day I got the fix. Outstanding support and amazing
							widgets!!!!!!!!!!"
						</p>
					</div>
					<div className="review">
						<Image
							width={60}
							height={60}
							src={
								'https://website-assets.commoninja.com/distribution/1708193182824_73x73.png'
							}
							alt={`Desert Barbell`}
						/>
						<h3>Desert Barbell</h3>
						{renderStars()}
						<p>
							"Yes yes yes. We were able to consolidate a lot of our web
							features through one service and one subscription. Lowered the
							cost of our site, looks and works great."
						</p>
					</div>
				</div>
			</StyledDiv>
		</Section>
	);
};

function renderStars() {
	return (
		<svg
			width="102"
			height="16"
			viewBox="0 0 102 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M101.138 6.04621C101.029 5.70813 100.73 5.46802 100.375 5.43605L95.5605 4.99849L93.6567 0.538446C93.5164 0.211582 93.1967 0 92.8415 0C92.4863 0 92.1666 0.211582 92.0262 0.53921L90.1224 4.99849L85.3071 5.43605C84.9533 5.46878 84.6545 5.70813 84.5446 6.04621C84.4348 6.38428 84.5362 6.75509 84.8039 6.98884L88.4431 10.1833L87.37 14.9147C87.2914 15.2626 87.4264 15.6222 87.7147 15.8308C87.8698 15.9429 88.0511 16 88.234 16C88.3917 16 88.5481 15.9575 88.6885 15.8734L92.8415 13.389L96.9929 15.8734C97.2967 16.0563 97.6797 16.0396 97.9674 15.8308C98.2559 15.6215 98.3907 15.2618 98.3122 14.9147L97.2391 10.1833L100.878 6.98947C101.146 6.75509 101.248 6.38492 101.138 6.04621Z"
				fill="#FFC24D"
			/>
			<path
				d="M16.6373 6.04621C16.5281 5.70813 16.2285 5.46802 15.8741 5.43605L11.0596 4.99849L9.15577 0.538446C9.01539 0.211582 8.6957 0 8.34049 0C7.98529 0 7.66559 0.211582 7.52522 0.53921L5.62142 4.99849L0.806112 5.43605C0.452308 5.46878 0.153483 5.70813 0.0436507 6.04621C-0.0661814 6.38428 0.0352511 6.75509 0.302895 6.98884L3.94212 10.1833L2.869 14.9147C2.79047 15.2626 2.92538 15.6222 3.21376 15.8308C3.36878 15.9429 3.55013 16 3.73302 16C3.8907 16 4.04711 15.9575 4.18749 15.8734L8.34049 13.389L12.492 15.8734C12.7958 16.0563 13.1787 16.0396 13.4665 15.8308C13.755 15.6215 13.8898 15.2618 13.8112 14.9147L12.7381 10.1833L16.3773 6.98947C16.645 6.75509 16.7472 6.38492 16.6373 6.04621Z"
				fill="#FFC24D"
			/>
			<path
				d="M37.7623 6.04621C37.6531 5.70813 37.3535 5.46802 36.9991 5.43605L32.1846 4.99849L30.2808 0.538446C30.1404 0.211582 29.8207 0 29.4655 0C29.1103 0 28.7906 0.211582 28.6502 0.53921L26.7464 4.99849L21.9311 5.43605C21.5773 5.46878 21.2785 5.70813 21.1687 6.04621C21.0588 6.38428 21.1603 6.75509 21.4279 6.98884L25.0671 10.1833L23.994 14.9147C23.9155 15.2626 24.0504 15.6222 24.3388 15.8308C24.4938 15.9429 24.6751 16 24.858 16C25.0157 16 25.1721 15.9575 25.3125 15.8734L29.4655 13.389L33.617 15.8734C33.9208 16.0563 34.3037 16.0396 34.5915 15.8308C34.88 15.6215 35.0148 15.2618 34.9362 14.9147L33.8631 10.1833L37.5023 6.98947C37.77 6.75509 37.8722 6.38492 37.7623 6.04621Z"
				fill="#FFC24D"
			/>
			<path
				d="M80.0133 6.04621C79.9041 5.70813 79.6045 5.46802 79.2501 5.43605L74.4355 4.99849L72.5317 0.538446C72.3914 0.211582 72.0717 0 71.7165 0C71.3613 0 71.0416 0.211582 70.9012 0.53921L68.9974 4.99849L64.1821 5.43605C63.8283 5.46878 63.5295 5.70813 63.4196 6.04621C63.3098 6.38428 63.4112 6.75509 63.6789 6.98884L67.3181 10.1833L66.245 14.9147C66.1664 15.2626 66.3014 15.6222 66.5897 15.8308C66.7448 15.9429 66.9261 16 67.109 16C67.2667 16 67.4231 15.9575 67.5635 15.8734L71.7165 13.389L75.8679 15.8734C76.1717 16.0563 76.5547 16.0396 76.8424 15.8308C77.1309 15.6215 77.2657 15.2618 77.1872 14.9147L76.1141 10.1833L79.7533 6.98947C80.0209 6.75509 80.1231 6.38492 80.0133 6.04621Z"
				fill="#FFC24D"
			/>
			<path
				d="M92.0262 0.53921C92.1666 0.211582 92.4863 0 92.8415 0V13.389L88.6885 15.8734C88.5481 15.9575 88.3917 16 88.234 16C88.0511 16 87.8698 15.9429 87.7147 15.8308C87.4264 15.6222 87.2914 15.2626 87.37 14.9147L88.4431 10.1833L84.8039 6.98884C84.5362 6.75509 84.4348 6.38428 84.5446 6.04621C84.6545 5.70814 84.9533 5.46878 85.3071 5.43605L90.1224 4.99849L92.0262 0.53921Z"
				fill="#FFC24D"
			/>
			<path
				d="M58.8873 6.04621C58.7781 5.70813 58.4785 5.46802 58.1241 5.43605L53.3096 4.99849L51.4058 0.538446C51.2654 0.211582 50.9457 0 50.5905 0C50.2353 0 49.9156 0.211582 49.7752 0.53921L47.8714 4.99849L43.0561 5.43605C42.7023 5.46878 42.4035 5.70813 42.2937 6.04621C42.1838 6.38428 42.2853 6.75509 42.5529 6.98884L46.1921 10.1833L45.119 14.9147C45.0405 15.2626 45.1754 15.6222 45.4638 15.8308C45.6188 15.9429 45.8001 16 45.983 16C46.1407 16 46.2971 15.9575 46.4375 15.8734L50.5905 13.389L54.742 15.8734C55.0458 16.0563 55.4287 16.0396 55.7165 15.8308C56.005 15.6215 56.1398 15.2618 56.0612 14.9147L54.9881 10.1833L58.6273 6.98947C58.895 6.75509 58.9972 6.38492 58.8873 6.04621Z"
				fill="#FFC24D"
			/>
		</svg>
	);
}
