import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledWidgetHero = styled.section`
	color: ${CSSVars.white};
	text-align: center;
	background: linear-gradient(255.96deg, #7d93ed 0%, #3047a6 100%);
	padding: 10px 20px 50px;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 20px 40px 100px;
	}

	.hero-wrapper {
		position: relative;

		.img-wrapper {
			margin-bottom: 20px;

			img {
				max-width: 120px;
				max-height: 120px;
				object-fit: contain;
				position: relative !important;
			}
		}

		.hero-image {
			position: relative;
			width: fit-content;
			height: fit-content;
			max-width: 100%;
			margin: 0 auto -150px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				margin: 0 auto -200px;
			}

			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				margin: 0 auto -300px;
			}

			img.main {
				max-width: 100%;
				height: 100%;
			}

			.glass {
				position: absolute;
				top: 0;
				bottom: 7.7px;
				left: 0;
				right: 0;
				background: white;
				z-index: 1;
				border-radius: 10px;
			}

			.stars {
				position: absolute;
				transform: scale(0.5);
				right: -30px;
				bottom: -40px;

				@media screen and (min-width: ${CSSVars.breakpointLG}) {
					transform: scale(1);
				}

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					bottom: -40px;
					right: -120px;
				}
			}

			.dagger {
				position: absolute;
				left: -35px;
				top: -50px;
				transform: scale(0.5);

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					transform: scale(1);
				}
			}

			img {
				z-index: 10;
				position: relative;
				transition: 0.2s;
			}

			img.right {
				position: absolute;
				left: 84%;
				top: -23%;
				width: 21%;

				@media screen and (min-width: ${CSSVars.breakpointSM}) {
					left: 81%;
					top: 33%;
				}

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					left: 87%;
					top: 43%;
				}
			}

			img.left {
				width: 19%;
				position: absolute;
				right: 85%;
				top: 15%;

				@media screen and (min-width: ${CSSVars.breakpointSM}) {
					right: 84%;
					top: 35%;
				}

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					right: 83%;
					top: 45%;
				}

				@media screen and (min-width: ${CSSVars.breakpointLG}) {
					top: 45%;
				}

				@media screen and (min-width: ${CSSVars.breakpointXL}) {
					right: 90%;
					top: 35%;
				}
			}
		}

		h1 {
			margin: 0 auto;
			font-weight: 600;
			max-width: 1100px;
			margin-bottom: 20px;
			/* width: fit-content; */

			font-size: ${CSSVars.fontSize2XL};
			text-align: left;

			&.coming-soon {
				position: relative;
				@media screen and (min-width: ${CSSVars.breakpointSM}) {
					width: fit-content;
				}

				&::after {
					content: 'Coming Soon';
					position: absolute;
					right: -10px;
					top: -13px;
					font-size: 12px;
					padding: 5px 15px;
					border-radius: 5px;
					background-color: ${CSSVars.primary300};
					box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					transform: rotate(10deg);
				}
			}

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				text-align: center;
				font-size: ${CSSVars.fontSize3XL};
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize4XL};
				max-width: 850px;
			}

			@media screen and (min-width: ${CSSVars.breakpointXL}) {
				font-size: ${CSSVars.fontSize6XL};
				max-width: 1050px;
			}
		}

		h2 {
			font-size: ${CSSVars.fontSizeLG};
			max-width: 780px;
			font-weight: 300;

			margin: 0 auto;
			text-align: left;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				text-align: center;
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				max-width: 850px;
				font-size: ${CSSVars.fontSizeXL};
			}
		}

		.button-wrapper {
			margin: 30px auto 100px 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			
			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				align-items: center;
				margin: 30px auto 100px;
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				margin: 50px auto 100px;
				font-size: ${CSSVars.fontSizeXL};
			}
		}

		a {
			font-size: ${CSSVars.fontSizeMD};
			font-weight: 600;
			padding: 16px 30px;
			background-color: ${CSSVars.primary800};
			font-size: ${CSSVars.fontSizeMD};
			margin-bottom: 30px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSizeXL};
			}

			&:hover {
				background-color: ${CSSVars.primary900};
			}
		}
	}
`;
