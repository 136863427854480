import { ReactNode } from "react";
import { StyledPopup } from "./popup.styles";
import { ReactDimmer } from 'react-dimmer';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';
import { CSSProperties } from "styled-components";

interface IPopupProps {
  children: ReactNode;
  className?: string;
  show: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  style?: CSSProperties;
}
export const Popup = ({ className = '', children, show, onClose, style = {} }: IPopupProps) => {

  return (
    <>
      <StyledPopup style={style} className={`popup-modal ${className} ${show ? 'show' : ''}`}>
        <IoMdClose onClick={() => onClose(false)} className="close" />
        {children}
      </StyledPopup>
      <ReactDimmer
        exitDimmer={onClose}
        isOpen={show}
        blur={1}
      />
    </>
  );
};