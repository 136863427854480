import React from 'react';
import { IPage, IPlugin } from '../../types';
import { Section } from '../section/section.comp';
import { StyledDiv } from './whyUseWidget.styles';

export const WhyUseWidget = ({ widget, page }: { widget: IPlugin, page?: IPage; }) => {
    return (
        <Section>
            <StyledDiv>
                <h2>Why Should You Use the <span>{widget.name}</span> Widget ?</h2>
                <div className="benefits">
                    {page && page.rewrites.keyBenefits.map((benefit) => (
                        <div key={benefit.text} className="card">
                            <h3>{benefit.title}</h3>
                            <p>{benefit.text}</p>
                        </div>
                    ))}
                    {!page && widget.keyBenefits.map((benefit) => (
                        <div key={benefit.text} className="card">
                            <h3>{benefit.title}</h3>
                            <p>{benefit.text}</p>
                        </div>
                    ))}
                </div>
            </StyledDiv>
        </Section>
    );
};
