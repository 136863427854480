import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	max-width: 930px;
	margin: 0 auto;
	color: ${CSSVars.gray900};

	&.dark {
		background-color: ${CSSVars.gray700};
		color: ${CSSVars.white};

		.faq-row {
			border-bottom: 1px solid ${CSSVars.gray600};
		}
	}

	h3 {
		font-size: ${CSSVars.fontSize2XL};
		font-weight: 600;
		text-align: center;
		margin-bottom: 50px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			margin: 0 auto 30px;
			font-size: ${CSSVars.fontSize4XL};
		}
	}

	.row-title {
		padding: 20px 0px !important;

		.row-title-text {
			font-weight: 600;
		}

		.icon-wrapper {
			top: 25px !important;

			svg {
				width: 30px !important;
				font-size: 30px;
			}
		}
	}

	.row-content-text {
		a {
			color: ${CSSVars.primary500};
			&:hover {
				color: ${CSSVars.primary300};
				text-decoration: underline;
			}
		}

		ul {
			list-style: disc;
		}
		ol {
			list-style: decimal;
		}
		li {
			margin: 10px 0 0 20px;
		}
	}
`;
