import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';
import { CustomLink } from '../link/link.comp';

export const StyledWidgetNav = styled.nav`
	transition: 0.2s;
	opacity: 0;
	pointer-events: none;

	&.show {
		opacity: 1;
		pointer-events: all;
		height: unset;
	}

	&.fixed {
		background-color: ${CSSVars.white};
		position: fixed;
		z-index: 100;
		top: 0px;
		left: 0;
		right: 0;
		background: #ffffff;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);

		.links {
			margin: 0 auto;
			padding: 15px 20px;
			max-width: 1420px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				justify-content: space-between;
			}

			.logo {
				color: ${CSSVars.gray900};

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					display: flex;
				}
			}

			a {
				color: ${CSSVars.gray400};

				&.active,
				&:hover {
					color: ${CSSVars.gray900};
				}
			}
		}
	}

	.links {
		padding: 15px 0;
		max-width: 1200px;
		display: flex;
		justify-content: center;
		margin: 20px auto 30px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			margin: 30px auto;
			/* justify-content: space-between; */
		}

		.examples {
			display: none;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				display: block;
			}
		}

		.how-to-add {
			display: none;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				display: block;
			}
		}

		.templates {
			display: none;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				display: block;
			}
		}

		.logo {
			color: ${CSSVars.white};
			align-items: center;
			gap: 20px;
			font-size: ${CSSVars.fontSizeMD};
			display: none;

			img {
				border-radius: 50%;
			}
		}

		ul {
			display: flex;
			align-items: center;
			gap: 30px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				gap: 50px;
			}

			a {
				color: #c3cdfa;
				font-weight: 500;
				transition: 0.2s;

				&.active,
				&:hover {
					color: ${CSSVars.white};
				}
			}

			.cta-li a {
				font-size: ${CSSVars.fontSizeSM};
				background-color: ${CSSVars.primary800};
				color: ${CSSVars.white};

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeMD};
				}

				&:hover {
					background-color: ${CSSVars.primary900};
					color: ${CSSVars.white};
				}
			}
		}
	}
`;

export const StyledFloatingButton = styled(CustomLink)`
	position: fixed;
	left: 50%;
	bottom: 30px;
	z-index: 100;
	transform: translateX(-50%);
	font-size: ${CSSVars.fontSize2XL};
	padding: 10px 40px;
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
	transform-origin: 0% 50%;

	&.shake {
		animation: tilt-shaking 0.15s linear infinite;
	}

	@keyframes tilt-shaking {
		0% {
			rotate: 0deg;
		}
		25% {
			rotate: 5deg;
		}
		50% {
			rotate: 0deg;
		}
		75% {
			rotate: -5deg;
		}
		100% {
			rotate: 0deg;
		}
	}
`;
