import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: ${CSSVars.gray900};
	max-width: 1075px;
	margin: 0 auto;
	padding-top: 200px;

	@media screen and (min-width: ${CSSVars.breakpointMD}) {
		padding-top: 250px;
	}

	.reviews {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 30px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			gap: 50px;
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		.review {
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: center;
			text-align: center;
			max-width: 325px;
			font-size: ${CSSVars.fontSizeMD};

			img {
				border-radius: 50em;
				background-color: black;
				object-fit: cover;
			}

			h3 {
				font-weight: 600;
			}
		}
	}
`;
