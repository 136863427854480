import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { StyledFloatingButton, StyledWidgetNav } from './widgetNavbar.styles';
import { IPlatform, IPlugin } from '../../types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { CustomLink } from '../link/link.comp';
import { trackEvent } from '../../lib/track';

interface IWidgetNavbarProps {
	widget: IPlugin;
	platform?: IPlatform;
	setFormPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WidgetNavbar = ({
	widget,
	platform,
	setFormPopupOpen,
}: IWidgetNavbarProps) => {
	const router = useRouter();
	const [showTemplatesTab, setShowTemplatesTab] = useState(false);
	const [shake, setShake] = useState(false);
	const { ref, inView } = useInView({
		threshold: 0,
	});
	const [isBrackets, setIsBrackets] = useState<boolean>(false);

	async function getWidgetTemplates() {
		try {
			const res = await fetch(
				`/api/get-templates?componentType=${widget.componentType}`
			);
			const data = await res.json();

			if ((data?.data || []).length > 0) {
				setShowTemplatesTab(true);
			}
		} catch (e) {}
	}

	const overviewPath = platform?.title
		? `/widgets/${widget.slug}/${platform.slug}`
		: `/widgets/${widget.slug}`;
	const pricingPath = platform?.title
		? `/widgets/${widget.slug}/${platform.slug}/pricing`
		: `/widgets/${widget.slug}/pricing`;
	const examplesPath = platform?.title
		? `/widgets/${widget.slug}/${platform.slug}/examples`
		: `/widgets/${widget.slug}/examples`;
	const templatesPath = platform?.title
		? `/widgets/${widget.slug}/${platform.slug}/templates`
		: `/widgets/${widget.slug}/templates`;
	const howToAddPath = `/widgets/${widget.slug}/${
		platform?.slug || ''
	}/how-to-add`;

	function renderNavContent() {
		return (
			<div className="links">
				<Link href={overviewPath} className="logo">
					<Image
						src={widget.logo}
						alt={`${widget.name} ${
							platform?.title ? `for ${platform.title}` : ''
						} logo`}
						width={30}
						height={30}
					/>
					{widget.name}
				</Link>
				<ul>
					<li>
						<Link
							className={
								router.pathname === `/widgets/[slug]` ||
								router.pathname === `/widgets/[slug]/[platform]`
									? 'active'
									: ''
							}
							href={overviewPath}
						>
							Overview
						</Link>
					</li>
					{widget.examples?.length > 0 && (
						<li className="examples">
							<Link
								className={
									router.pathname === `/widgets/[slug]/examples` ||
									router.pathname === `/widgets/[slug]/[platform]/examples`
										? 'active'
										: ''
								}
								href={examplesPath}
							>
								Examples
							</Link>
						</li>
					)}
					{showTemplatesTab && (
						<li className="templates">
							<Link
								className={
									router.pathname === `/widgets/[slug]/templates` ||
									router.pathname === `/widgets/[slug]/[platform]/templates`
										? 'active'
										: ''
								}
								href={templatesPath}
							>
								Templates
							</Link>
						</li>
					)}
					<li className="how-to-add">
						<Link
							className={
								router.pathname === `/widgets/[slug]/[platform]/how-to-add` ||
								router.pathname === `/widgets/[slug]/how-to-add`
									? 'active'
									: ''
							}
							href={howToAddPath}
						>
							How to add
						</Link>
					</li>
					{/* {widget.status !== 'coming-soon' &&
            <li>
              <Link
                className={
                  router.pathname === `/widgets/[slug]/pricing` ||
                    router.pathname === `/widgets/[slug]/[platform]/pricing`
                    ? "active"
                    : ""
                }
                href={pricingPath}
              >
                Pricing
              </Link>
            </li>
          } */}

					{!inView && (
						<>
							{widget.status !== 'coming-soon' ? (
								<>
									<li>
										<Link
											className={
												router.pathname === `/widgets/[slug]/pricing` ||
												router.pathname === `/widgets/[slug]/[platform]/pricing`
													? 'active'
													: ''
											}
											href={
												isBrackets
													? `/widgets/${widget.slug}/pricing`
													: `/pricing`
											}
										>
											Pricing
										</Link>
									</li>
									<li className="cta-li">
										<CustomLink
											external
											title={`Free ${widget.name} ${
												platform?.title ? `for ${platform.title}` : ''
											}`}
											href={`/${widget.slug}/editor`}
											onClick={() => {
												trackEvent(`Floating menu try for free`, { serviceName: widget.serviceName });
											}}
										>
											Create Now
										</CustomLink>
									</li>
								</>
							) : (
								<li className="cta-li">
									<CustomLink
										external
										title={`Get Notified when ${widget.name} get released.`}
										className="try-button"
										onClick={() => {
											// trackEvent(`Coming Soon Landing page ${widget.name}`);
											if (setFormPopupOpen) {
												setFormPopupOpen(true);
											}
										}}
									>
										Get Notified
									</CustomLink>
								</li>
							)}
						</>
					)}
				</ul>
			</div>
		);
	}

	useEffect(() => {
		getWidgetTemplates();

		if (
			typeof window !== 'undefined' &&
			window.location.pathname.includes('brackets')
		) {
			setIsBrackets(true);
		} else {
			setIsBrackets(false);
		}

		// Toggle shake animation once every 5 seconds for 2 seconds
		const interval = setInterval(() => {
			setShake(true);
			setTimeout(() => {
				setShake(false);
			}, 1000);
		}, 5000);

		return () => clearInterval(interval);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<StyledWidgetNav ref={ref} className={`${inView ? 'show' : ''}`}>
				{renderNavContent()}
			</StyledWidgetNav>
			<StyledWidgetNav className={`fixed ${!inView ? 'show' : ''}`}>
				{renderNavContent()}
			</StyledWidgetNav>
			<StyledFloatingButton
				className={`floating-button ${shake ? 'shake' : ''}`}
				style={{ display: !inView ? 'block' : 'none' }}
				href={`/${widget.slug}/editor`}
				onClick={() => {
					trackEvent(`Floating try for free`, {
						serviceName: widget.serviceName,
					});
				}}
			>
				Try for free!
			</StyledFloatingButton>
		</>
	);
};
