// @ts-nocheck
import Script from 'next/script';
import React from 'react';
import FAQ from 'react-faq-component';
import { Section } from '../section/section.comp';
import { StyledDiv } from './faq.styles';


interface IFaqProps {
    faqs: { q: string, a: string; }[];
    mode?: 'light' | 'dark';
}

export const Faq = ({ faqs = [], mode = 'light' }: IFaqProps) => {
    if (!faqs.length) return <></>;
    const data = {
        rows: faqs.map((faq) => ({ title: faq.q.replace(/<[^>]*>/g, ''), content: faq.a }))
    };

    const styles = {
        rowTitleColor: mode === 'light' ? "#292929" : '#fff',
        rowTitleTextSize: "18px",
        rowContentTextSize: "16px",
        rowContentColor: mode === 'light' ? "#292929" : '#fff',
        rowContentPaddingBottom: '20px',
        rowContentPaddingTop: '0px',
        arrowColor: "#6881E9",
        bgColor: mode === 'light' ? "#fff" : '#171B2C',
    };

    const config = {
        animate: true,
        tabFocus: true,
    };

    return (
        <>
            <FaqScripts faqs={faqs} />
            <Section mode={mode}>
                <StyledDiv className={mode}>
                    <h3>FAQ</h3>
                    <FAQ config={config} styles={styles} data={data} />
                </StyledDiv>
            </Section>
        </>
    );
};


const FaqScripts = ({ faqs }: { faqs: { q: string, a: string; }[]; }) => {
    function extractContent(str: string) {
        if (typeof document === 'undefined') {
            return str;
        }
        const div = document.createElement('div');
        div.innerHTML = str;
        return (div.textContent || div.innerText).replace(/\n/g, ' ').trim();
    };

    return (
        <Script id="faqs-script" type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            ${faqs.map((item) => (
            `{
                "@type": "Question",
                "name": "${extractContent(item.q)}",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${extractContent(item.a.replace(/"/g, "'"))}"
                }
              }`
        ))}
          ]
        }
      `}</Script>
    );
};