import styled from 'styled-components';
export const StyledPopup = styled.div`
  opacity: 0;
  z-index: -1;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scale(0.9);
  transition: 0.2s;
  background-color: #fff;
  &.show {
    transform: translate(-50%,-50%) scale(1);
    z-index: 1000;
    opacity: 1;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: black;
    font-size: 24px;
    cursor: pointer;
  }

`;
